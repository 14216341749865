import React from "react"
import styles from "./description.module.css"

const Description = props => {
  return (
    <div className={styles.jobDescriptionContainer}>
      <div className={styles.blockContainer}>
        {props.headline &&
          <div className={styles.headline} dangerouslySetInnerHTML={{
            __html: props.headline,
          }}>
          </div>
        }
        {props.text &&
          <div className={styles.text} dangerouslySetInnerHTML={{
            __html: props.text,
          }}>
          </div>
        }
      </div>
    </div>
  )
}

export default Description
