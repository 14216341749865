import React, { useEffect } from "react"
import { graphql } from 'gatsby'
import imageSrc from './../lib/image-src'
import styles from "../styles/jobs.module.css"
import HeaderMenu from "../components/header/top-header-with-menu/topHeaderWithMenu"
import Header from "../components/jobs-components/header/header"
import Description from "../components/jobs-components/description/description"
import Offers from "../components/jobs-components/company-offers/offers"
import Tech from "../components/jobs-components/technologies/tech"
import AboutDW from "../components/jobs-components/aboutDW/aboutDW"
import Career from "../components/career/career"
import Footer from "../components/footer/footer"
import { Helmet } from "react-helmet"
import CookieConsent from "../components/cookie-consent";
import * as Fathom from 'fathom-client';


const Jobs = ({data, location}) => {
  const cookieConsentRef = React.createRef();

  useEffect(() => {
    document.querySelector(".editorsButton").addEventListener("click", () => {
      if (data.datoCmsStellenangebote && data.datoCmsStellenangebote.useFathomCode) {
        Fathom.trackGoal(data.datoCmsStellenangebote.useFathomCode, 0);
      }      
    }, false)
  }, [])

  const benefits= [];
  data.datoCmsStellenangebote.wasWirBieten.forEach(element => {
    if (element.internal.type === "DatoCmsBenefit") {
      benefits.push(element)
    }
  });
  return (
    <div>
      <CookieConsent locale="de" visibilityRef={cookieConsentRef} />
      <Helmet>
        <title>{data.datoCmsStellenangebote.seo.title}</title>
        <meta name="description" content={data.datoCmsStellenangebote.seo.description} />
        <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org/",
          "@type": "JobPosting",
          "title": "${data.datoCmsStellenangebote.stellenbezeichnung}",
          "description": "${data.datoCmsStellenangebote.seo.description}",
          "hiringOrganization" : {
            "@type": "Organization",
            "name": "${data.allDatoCmsTemplateTexte.nodes[0].labelCopyright}",
            "sameAs": "https://digitalwert.de/" 
          },
          "industry": "Unternehmensberatung, Web Entwicklung",
          "employmentType": "${data.datoCmsStellenangebote.employmentType}",
          "workHours": "${data.datoCmsStellenangebote.teilzeitWochenstunden}",
          "datePosted": "${data.datoCmsStellenangebote.meta.firstPublishedAt}",
          "validThrough": "",
          "jobLocation": {
            "@type": "Place",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "${data.allDatoCmsTemplateTexte.nodes[0].companyStreetAddress}",
              "addressLocality": "${data.allDatoCmsTemplateTexte.nodes[0].companyAddressCity}",
              "postalCode": "${data.allDatoCmsTemplateTexte.nodes[0].companyAddressZip}",
              "addressCountry": "DE" 
            }
          },
          "qualifications": "${data.datoCmsStellenangebote.qualifications}"
        }
        `}
        </script>
      </Helmet>
      <HeaderMenu url = {location.href} page="jobs" />
      <Header
        banner={data.datoCmsStellenangebote.header[0].banner}
        headline={data.datoCmsStellenangebote.header[0].headline}
        text={data.datoCmsStellenangebote.header[0].text}
        image={imageSrc(data.datoCmsStellenangebote.header[0].image)}
        imgAlt={data.datoCmsStellenangebote.header[0].image?.alt}
        prevPath= {location.state ? location.state.prevPath : "/"}
      />
      <main className={`${styles.mainContainer}`}>
        {
          data.datoCmsStellenangebote.inhalt.map((contentElement, i) => {
            switch (contentElement.internal.type) {
              case "DatoCmsSimpletext":
                return <Description
                  key={i}
                  headline={contentElement.headline}
                  text={contentElement.text}
                />
              case "DatoCmsTechnology":
                return (
                <>
                <Tech
                  key={i}
                  title={contentElement.title}
                  description={contentElement.description}
                  images={contentElement.techicons}
                />
                <Offers
                  title={data.datoCmsStellenangebote.wasWirBieten[0].title}
                  benefits={benefits}
                />
                </>)
              case "DatoCmsCtaBlue":
                return <AboutDW
                  headline={contentElement.headline}
                  subHeadline={contentElement.subheadline}
                  button={contentElement.buttonText}
                  url={contentElement.buttonUrl}
                  bg={imageSrc(contentElement.background)}
                />
              case "DatoCmsAufgabenstellung":
                  return <Career
                    page = "jobs"
                    headline={contentElement.berschriftH2}
                    text= {contentElement.text}
                    list={contentElement.liste}
                  />
                default:
                  return ''
            }
          })
        }
      </main>
      <Footer cookieConsentRef={cookieConsentRef} />
    </div>
  )
}

export default Jobs

export const query = graphql`
query JobTemplate($id: String!) {
  allDatoCmsTemplateTexte {
    nodes {
      companyAddressCity
      companyAddressZip
      companyStreetAddress
      labelCopyright
    }
  }

  datoCmsStellenangebote(id: { eq: $id }) {
    seo {
      description
      title
      twitterCard
    }
    id
    slug
    stellenbezeichnung
    teilzeit
    teilzeitWochenstunden
    employmentType
    qualifications
    useFathomCode
    meta {
      firstPublishedAt
    }
    wasWirBieten {
      ... on DatoCmsTitle {
        id
        title
        internal {
          type
        }
      }
      ... on DatoCmsBenefit {
        id
        internal {
          type
        }
        icon {
          url
        }
        text1
        text2
      }
    }
    inhalt {
      ... on DatoCmsCtaBlue {
        id
        buttonText
        buttonUrl
        background {
          fluid(imgixParams: { auto: "compress" }) {
            src
          }
          url
        }
        subheadline
        headline
        internal {
          type
        }
      }
      ... on DatoCmsAufgabenstellung {
        id
        berschriftH2
        text
        liste
        internal {
          type
        }
      }
      ... on DatoCmsTechnology {
        id
        title
        description
        techicons {
          title
          alt
          fluid(imgixParams: { auto: "compress" }) {
            src
          }
          url
        }
        internal {
          type
        }
      }
      ... on DatoCmsSimpletext {
        id
        headline
        text
        internal {
          type
        }
      }
    }
    header {
      banner
      headline
      image {
        alt
        fluid(imgixParams: { auto: "compress" }) {
          src
        }
        url
        title
      }
      internal {
        type
      }
      text
    }
  }
}
`
