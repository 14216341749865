import React from "react"
import styles from "./tech.module.css"

const Tech = props => {
  return (
    <div className={styles.techContainer}>
      <div className={styles.textContainer}>
        {props.title && <h3>{props.title}</h3>}
        {props.description && <p>{props.description}</p>}
      </div>
      <div className={styles.logosContainer}>
        {props.images.map((contentElement, i) => {
          return (
            <div key={i} className={styles.itemContainer}>
              <div
                className={styles.icon}
                style={
                  contentElement.url != null
                    ? { backgroundImage: `url(${contentElement.url})` }
                    : {}
                }
              ></div>
              {contentElement.title && <p>{contentElement.title}</p>}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Tech
