import React from "react"
import styles from "./header.module.css"
import { Link } from 'gatsby'

const Header = props => {
  return (
    <header className={styles.headerContainer}>
      <div className={styles.textContainer}>
        <Link to={props.prevPath}><button>Zurück</button></Link>
        {props.banner && <p className={styles.bannerP}>{props.banner}</p>}
        {props.headline && <h1 className={styles.h1}>{props.headline}</h1>}
        {props.text && (
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: props.text }}
          ></div>
        )}
      </div>
      {props.image && (
        <img
          className={styles.bannerImg}
          src={props.image}
          alt={props.imgAkt}
        />
      )}
    </header>
  )
}

export default Header
