import React from "react"
import styles from "./aboutDW.module.css"

const AboutDW = props => {
  return (
    <div
      className={styles.aboutDWContainer}
      style={
        props.url
          ? {
              backgroundImage: `url(${props.bg})`,
            }
          : {}
      }
    >
      <div className={styles.contentContainer}>
        {props.headline && (
          <div
            className={styles.headline}
            dangerouslySetInnerHTML={{
              __html: props.headline,
            }}
          ></div>
        )}
        {props.subHeadline && <p>{props.subHeadline}</p>}
        {props.button && props.url && (
          <a href={props.url}>
            <button>{props.button}</button>
          </a>
        )}
      </div>
    </div>
  )
}

export default AboutDW
