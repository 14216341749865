import React from "react"
import styles from "./offers.module.css"
import { graphql, StaticQuery } from "gatsby"

const Offers = props => {
  return (
    <StaticQuery
      query={graphql`
      query OfferQuery {
        allDatoCmsStellenangebote(filter: {id: {eq: "DatoCmsStellenangebote-18020659-de"}}) {
          nodes {
            id
            slug
            wasWirBieten {
              ... on DatoCmsTitle {
                id
                title
                internal {
                  type
                }
              }
              ... on DatoCmsBenefit {
                id
                internal {
                  type
                }
                icon {
                  url
                }
                text1
                text2
              }
            }
          }
        }
      }      
      `}
      render={data => (
        <div className={styles.offercontainer}>
        <h3>{data.allDatoCmsStellenangebote.nodes[0].wasWirBieten[0].title}</h3>
        <div className={styles.itemsContainer}>
          {data.allDatoCmsStellenangebote.nodes[0].wasWirBieten.map((element, i )=> {
            switch (element.internal.type) {
              case "DatoCmsBenefit":
            return (
              <div key={i} className={styles.itemContainer}>
                <div className={styles.IconAndtexts}>
                  {element.icon && (
                    <div
                      className={styles.icon}
                      style={{
                        backgroundImage: `url(${element.icon.url})`,
                      }}
                    ></div>
                  )}
                  <div className={styles.iconTextsContainer}>
                   <p>{element.text1}</p>
                   <p>{element.text2}</p>
                  </div>
                </div>
              </div>
            )
            default:
                  return ''
            }
          })}
        </div>
        
      </div>
      )}
    />
  )





}

export default Offers
